.text-red {
  color: var(--red-color);
}

.color-purple-dark {
  color: var(--secondary-color);
}

.text-color-primary {
  color: var(--primary-color-dark);
}

.text-color-green {
  color: var(--green-color);
}

.text-color-dark-green {
  color: var(--green-color-dark);
}

.color-gray {
  color: var(--gray-color);
}

.text-white {
  color: var(--text-white);
}

.text-black {
  color: var(--text-black);
}

.text-gray {
  color: var(--tittle-color);
}

.text-blue {
  color: blue
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center
}

.text-fz-1-5 {
  font-size: 1.5rem;
}

.text-fz-2 {
  font-size: 2rem;
}

.text-fz-3 {
  font-size: 3rem;
}

.text-fz-4 {
  font-size: 4rem;
}

.text-fz-5 {
  font-size: 5rem;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-semibold {
  font-weight: 600;
}

.text-justify {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto
}


/* ---------------------------------------*/
/* ---------->>> LOGIN <<<-----------*/
/* ---------------------------------------*/

.text-welcome-login {
  margin-bottom: 4px;
  /* height: 35px; */
  font-size: 1.7rem;

}

.text-down-login {
  font-size: 1rem;
  margin-right: 5px;
}

.text-link-red {
  text-decoration: none;
  font-size: 1rem;
  border-bottom: 1px solid var(--red-color);
}


/* ---------------------------------------*/
/* ---------->>> REGISTER <<<-----------*/
/* ---------------------------------------*/

.text-name-profile {
  color: var(--text-black);
  
  font-size: 1.3rem;
}

.text-information-profile {
  font-size: 1rem;
}

.tittle {
  color: var(--tittle-color);

  font-size: 1rem;
}

.titleHome {
  color: var(--tittle-color-home);

  font-size: 1.2rem;
}

.titleRegister {
  color: var(--text-white);

  font-size: 200%;
}

.createStoryText {
  color: #5746a8;
  text-align: center;
 
}

.createStoryText:hover {
  cursor: pointer;
}

.viewStoryText {
  text-align: center;
  color: var(--paragraph-color)
}

.viewStoryText:hover {
  cursor: pointer;
}

.text-name-navbar {

  cursor: pointer;
  
  font-size: 1.2rem;
}

.littleText {
  font-size: x-small;
  font-weight: normal;
}



.linkGray {
  text-decoration: none;
  font-size: 0.8rem;
  color: gray;
}


.textMenuRighTittleContent {
  color: var(--tittle-color-home);
  color: var(--text-black);

  font-size: 0.8rem;
}

.textMenuRighTittleDescription {
  color: var(--tittle-color-home);
  font-size: 0.8rem;
}

.textClassified {
 
  color: var(--red-color);
}

.centerText {
  text-align: center;
  width: 50%;
}

.text-error {
  color: #f34242;
  font-size: 0.8rem;
  font-size: x-small
}

.center-contents-column{
  width: 100%;
}

.contents-div{
  display: contents;
}

.center-contents-div{
  width: 50%;
  text-align: center;
}

/* ---------------------------------------*/
/* ---------->>> MODAL NEW POST <<<-----------*/
/* ---------------------------------------*/

.text-modal-new-post {
  text-align: center;

  color: var(--text-black);
  font-size: 1.2rem;
  padding: 20px 0px 15px 0px;
}

.text-warning{
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.text-tap {
  font-size: 0.7rem;
}

.text-tap-professional {
  font-size: 0.9rem;
}

.text-follows-friends-profile {
  font-size: 1.15rem;
}

.text-tag-friend-new-post {
  color: var(--gray-color);
  z-index: 500;
  font-size: 0.8rem;
}

.home-text-menu-icon {
  font-size: 0.8rem;
}

.text-time-audio {
  text-align: end;
  width: 100%;
  font-size: 0.8rem;
}

.friends-count-friend-list {
  margin-left: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  font-size: 2.4rem;
}

.tittle-edit-info-panels {
  font-size: 1.2rem;

  color: var(--text-black);
  margin-bottom: 10px;
  margin-left: 4px;
  justify-content: space-between;
}