.img-card-work{
    border-radius: 20px 20px 0 0;
}
.notice_list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center
}
.titulo-noticias {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
}
@media screen and (max-width: 1200px) {
    .titulo-noticias {
        text-align: center;
        justify-content: center;
        display: flex;
       font-size: 2.5rem;
       padding: 8px;
    }
}
@media screen and (max-width: 768px) {
    .titulo-noticias  {
        font-size: 2rem;
        padding: 0;
    }
    
}