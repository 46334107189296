.carousel-container {
    max-width: 1800px;
    height: auto;
    width: 100%;
    overflow: hidden; /* Asegura que las imágenes no se desborden del contenedor */
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Centra el carrusel horizontalmente */

}
@media (max-width: 1000px) {
    .carousel-container {
        width: auto; /* Ajusta el ancho al 100% del contenedor padre */
        height: auto; /* Ajusta la altura automáticamente */
        margin: 0; /* Elimina el margen */
    }

    .carousel-img-size {
        height: auto; /* Ajusta la altura automáticamente */
        object-fit: contain; /* Mantiene la proporción de la imagen sin recortar */
    }
}
@media screen and (max-width: 768px) {
    .carousel-container {
        margin-left: 0px;
        margin-right: 0px; /* Centra el carrusel horizontalmente */
        width: 100%;
        height: auto;
    }
    
}

.carousel-img-size {
    height: auto; /* Asegura que ocupe todo el alto del contenedor */
    width: auto; /* Asegura que ocupe todo el ancho del contenedor */
    
    object-fit: cover; /* Mantiene la proporción de la imagen y llena el contenedor */
}
@media screen and (max-width: 768px) {
    .carousel-img-size {
        height: auto;
        width: 100%;
    }
    
}

.img-carousel {
    width: auto;
    height: 600px;
    min-width: 1100px;
    object-fit: cover;
}
@media screen and (max-width: 768px) {
    .img-carousel {
      width:  auto;
        height: 300px;
        min-width: 300px;
    }
    
}
.limited-text {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limita a 3 líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Añade puntos suspensivos al final */
    text-align: justify;
    padding-inline: 24px;
    padding-top:0 ;
    padding-bottom: 0;
    color: white;
    

  }
@media screen and (max-width: 768px) {
    .limited-text {
        -webkit-line-clamp: 3;
        font-size: 0.8rem;
        text-align: center;
    }
    
}
.new-title {
    font-size: 1.4rem;
    font-weight:550;
    color: white;
    text-align: center;
    padding: 10px;
    
}
@media screen and (max-width: 768px) {
    .new-title {
        font-size: 1rem;
    }
    
}

.New-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 34, 46, 0.452);
    border-radius: 10px;
    padding: 8px;
    right: 23%;
    left: 23%;
    bottom: 9%;
}
@media screen and (max-width: 768px) {
    .New-container {
        right: 0;
        left: 0;
    }
    
}