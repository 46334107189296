/* ShippingInfo.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.shipping-info {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.shipping-info__item {
  margin-bottom: 20px;
}

.shipping-info__item h3 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}

.shipping-info__item p {
  text-align: justify;
  font-size: 16px;
  line-height: 1.5;
}