.width-img{
    width: 100%;
}
.margin-bottom-img{
    margin-bottom: 70px;
}

.job_list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center
}
.container-img-work{
    display: flex;
    justify-content: center; /* Centra el contenedor horizontalmente */
    align-items: center; /* Opcional, centra verticalmente si es necesario */
    text-align: center; /* Centra el texto dentro del contenedor */
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
    margin-left: auto; /* Asegura que no haya margen en los lados */
    margin-right: auto;
 
}
.container-text-work{
    align-content: center;
    padding: 20px;
    justify-content: center;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.Work-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}
