.cls-back-next {
    cursor: pointer;
    fill: var(--text-white);
}

.cls-back-next:active {
    transform: translateY(0.5px);
}

.svg-container {
    display: flex;
    align-content: center;
    align-items: center;
}



.white-svg {
    fill: rgb(251, 223, 223)
}

.white-svg:hover {
    fill: #ffffff;
}

.white-svg-without-hover {
    fill: #ffffff;
    stroke: #ffffff;
    stroke-width: 2px
}

.black-svg {
    fill: var(--text-black);
    cursor: pointer;
}

.purple-svg {
    fill: var(--secondary-color);
    cursor: pointer;
}

.cls-change-profile-picture {
    fill: var(--text-white);
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 1em/1em;
    padding-left: 10px;
    padding-right: 10px;
}

.svg-multiple-post {
    fill: #ffffff;
    stroke: #ffffff;
    stroke-width: 5px
}


.svg-logout {
    stroke: black;
    stroke-width: 1px
}


.cls-svg-tab {
    margin-top: 10px;
    margin-bottom: 5px;
    fill: inherit;
}

.fill-parent {
    fill: inherit;
    cursor: pointer;
}

.new-post-svg {
    cursor: pointer;
    fill: #ffffff;
    width: 23px;
    height: 23px;
    background-color: #f53c3c;
    border-radius: 1em/1em;
}

.new-post-svg-community {
    margin-right: 10px;
    cursor: pointer;
    fill: #ffffff;
    width: 40px;
    height: 40px;
    background-color: #f53c3c;
    border-radius: 100px;
}

.svg-menu {
    fill: var(--tittle-color);
    display: block;
}

.back-svg {
    transform: rotate(180deg);

}

.back-svg:active {
    transform: rotate(180deg) translateY(0.5px);
}

.options-svg-add-friend {
    margin-top: 6px;
    margin-right: 5px;
    background-color: var(--primary-color);
    padding: 8px;
    border-radius: 100px;
    cursor: pointer;
    transform: rotate(45deg);
    fill: white;
    stroke: white;
    stroke-width: 7px
}

.options-msg {
    transform: rotate(90deg);
}

.delete-red {
    fill: var(--red-color);
    stroke: var(--red-color);
    stroke-width: 2px
}

.search-icon {
    fill: none;
    stroke: #ff9bb2;
    stroke-width: 5px;
}