.img_icon{
    border-radius: var(--border-radius);
    fill: var(--secondary-color);
    padding-inline: 12px;
    padding-block: 10px;
    background-color: var(--primary-color-dark);
    margin-right: 10px;    
    width: 27px;
    height: 30px;
}

.title_text{
    color: var(--primary-color-dark);
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

.title_text .title_text_highlight{
    color: var(--secondary-color);
}