.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s , transform 0.3s;
}

.floating-button:hover {
  background-color: #128C7E;
  transform: translateY(-5px);
}

.hover-message {
  position: absolute;
  bottom: 6px;
  right: 45px;
  background-color: #25D366;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;
}

.hover-message.visible {
    opacity: 1;
    transform: translateY(0);
}