.button-style {
    padding: 0;
    margin-top: 20px;
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;
    outline: none;
    width: 80%;
    color: var(--secondary-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;
    -webkit-box-shadow: var(--shadow-button);
    box-shadow: var(--shadow-button);
}

.button-style:hover {
    background-color: var(--primary-color-dark);
}

.button-style:active {
    background-color: var(--primary-color-dark);
    transform: translateY(0.5px);

    
}

.button-style.white {
    color:  var(--button-white-color);
    background-color:var(--secondary-color);
}

.button-style.white:hover {
    background-color: var(--primary-color-dark);
    color:var(--button-white-color);
}

.button-style.white-hover {
    background-color: var(--primary-color-dark);
    color:var(--button-white-color);
}

.button-style.white:disabled {
    background-color: var(--gray-color-light);
}

@media only screen and (max-width: 600px) {
    .button-style {
        padding: 8px;
        width: auto;
        font-size: 0.8rem;
        border-radius: 8px;
    }
}

@media only screen and (min-width: 768px) {
    .button-style {
        padding: 8px;
        width: auto;

        font-size: 1rem;
        border-radius: 8px;
    }
}
