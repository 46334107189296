.content_card_list {
    display: grid;
    position: relative;
    cursor: pointer;
    -webkit-box-shadow: var(--shadow-button);
    padding-top:10px; 
    box-shadow: var(--shadow-button);
    border-radius: var(--border-radius);
    /* height: calc(var(--height-card-list) + 60px); */
    height: fit-content;
    width: 250px;
    justify-content: center;
    box-sizing: border-box;
    background-color: var(--background-white);
   
   
}

.content_card_list:hover {
    height: fit-content;
    text-align: center;
    overflow: visible; /* Cambiado a 'visible' para mostrar el contenido expandido */
    z-index: 10; /* Aumenta el nivel en el eje Z para que esté sobre otras cartas */
   
}

.content_img_product {
    /* height: var(--height-card-list); */
    width: 100%;
}

.img_product {
    height: 20vh;
    width: 20vh;
    object-fit: contain;
    object-position: top;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    padding-top: 20px;
}

.content_product_name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 88%;
    height: 44px;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-inline: auto;
}

.product_name {
    color:black;
    text-align: center;
    line-height: 1.4rem;
    overflow: hidden;
    display: flex;
    font-weight: 500;
    font-size:medium;
    font-family: 'Montserrat' ;
    align-items: flex-start;
    height: 2.8rem;
    

}

.information_container {
    
    padding: 0% 6% 3% 6%;
   
}

.price_text {
    box-shadow: 1px 1px 8px 2px rgb(0 0 0 / 20%);
    border-radius: var(--border-radius);
    text-align: center;
    padding: 0px 0px;
    width: 100%;
    color: var(--secondary-color);
}

.points_text {
    box-shadow: 1px 1px 8px 2px rgb(0 0 0 / 20%);
    border-radius: var(--border-radius);
    text-align: center;
    margin-top: 5px;
    padding: 5px 0px;
    width: 100%;
    color: var(--secondary-color);
}

.quantity_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
}
.quantity_container_in_modal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 8px;
    grid-column: 1 / span 3;
    max-height: 30px;
}
.btn_quantity_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
}

.quantity_product_container {
    display: flex;
    justify-content: space-between;
    width: 74px;
    align-items: center;

}

.quantity_icon {
    height: 11px;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .quantity_icon {
        height: 10px;
    }
    
}
.quantity_product {
    /* width: 200px;*/
    text-align: center;
    /* box-shadow: var(--shadow-input); */
    /* border-color: var(--secondary-color-light);  */
    border: none;
    width: 100%;
    font-weight: 600;
    color: var(--primary-color-dark);
    font-size: 12px;
}
@media screen and (max-width: 768px) {
    .quantity_product {
        font-size: 12px;
    }
    
}

.quantity_product_warning {
    margin-bottom: 0;
    font-size: 10px;
    color: var(--primary-color-dark);
    text-align: center;
}

.quantity_product_title {
    margin-top: 0px;
    font-size: 12px;
    color: #1A512E;
    font-weight: 550;
}
@media screen and (max-width: 540px) {
    .quantity_product_title {
        font-size: 10px;
    }
}

.marketplace_add {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:11px;
  border: none;
  background-color:#F6C1D6; /* O el color que desees */
  cursor: pointer;
  border-radius: 999em;
  height:auto;


}

.marketplace_add_img {
    max-height: 20px; /* Ajusta este valor según el tamaño deseado */
    padding:4px; /* Espacio entre la imagen y el texto */
    padding-right:4px ;
  }
  
  .marketplace_add_text {
    font-size: 14px;
    font-family: 'Montserrat' ;
    font-weight: 550;
    color: #842464; /* O el color que desees */


  }
.overlayImage {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    height: fit-content;
    opacity: 1;
   
/* Propiedad Overlay - para la imagen superpuesta con los iconos */
}

@media (orientation: landscape) {
    .overlayImage {
      transform: rotate(0deg);
      width: 100%;
      height: fit-content;
      top: 10px;
    }
  
    .content_card_list {
      /* Ajusta el tamaño según sea necesario */
      width: 80%; /* Ejemplo de ajuste */
      height: max-content;
    }
  }