.nav-style {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(115, 156, 139, 0.06) 0px 2px 4px -1px;
    font-family: montserrat-regular, sans-serif;
    font-weight: 500;
    background-color: #fff;
    padding-top: 0px;
    border-top: 0px solid #F2A2C0;
    height: 96px;
    padding: 0px 120px 0px 120px;
    margin: 0px;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 1095px) {
    .nav-style {
        padding: 0px;
        margin: 0px;
    }
}

.style-logotipo {
    width: 180px;
}
@media screen and (max-width: 1095px) {
    .style-logotipo {
        width: 80px;
    }
    
}
.grid-nav {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
}
@media screen and (max-width: 990px) {

    .grid-nav {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }

}
.whitout-margin {
    margin: 0;
}

.nav-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
    content: center;
    position: relative;
    z-index: 10; /* Asegúrate de que sea mayor que los otros elementos */
   
}

@media screen and (max-width: 990px) {
    .nav-container {
        display: flex;
        gap: 22rem;
        align-items: center;
        z-index: 10; /* Añadir z-index para asegurarse de que esté en la capa superior */
        justify-content: space-around;
    }

    .navbar-collapse {
        position: absolute;
        top: 100%; /* Despliega justo debajo de la barra de navegación */
        left: auto;
        width: 400px; /* Asegura que ocupe todo el ancho */
        background-color: white; /* Para que se distinga del contenido */
        z-index: 20; /* Superponer sobre otros elementos */
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 768px) {
    .nav-container {
        display: flex;
        gap: 20rem;
        align-items: center;
        z-index: 10; /* Añadir z-index para asegurarse de que esté en la capa superior */
        justify-content: space-around;
    }   
}
@media screen and (max-width: 576px) {
    .nav-container {
        display: flex;
        gap: 11rem;
        align-items: center;
        z-index: 10; /* Añadir z-index para asegurarse de que esté en la capa superior */
        justify-content: space-around;
    }   
    
}

.margin-nav-titles {
    border-bottom-width: 24px;
    border-bottom-color: red;
    padding-top: 8px;
}

.nav-link {
    font-size: 14px;
    font-style: 'montserrat-blod';
    justify-content: center;
    align-items: center;
}

.nav-link-active {
    font-weight: 700;
}

.btn {
    height: 120px;
    padding: 8px 16px 8px 16px;
    font-size: 16px;
}

.btn:hover {
    border-bottom-color: #80225f;
    border-bottom-width: 10px;
}

.links-style {
    text-decoration: none;
    color: black;
}
.basic-navbar-nav {
    display: flex;
    justify-content: space-between;
    color: white;
}
.NavBar-Bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 14px;
    margin: 6px;
    margin-bottom: 8px;

}