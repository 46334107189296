.background_container {
    position: fixed;
    background-color: var(--background-color-transparent-dark);
    z-index: 300;
    margin-top: var(--height-navbar);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    padding-top: 0px;
    margin-bottom: 250px;
}

@media only screen and (orientation: landscape) {
    .background_container {
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;
        height: auto;
        padding: 10px;
    }
}

.modal_content {
    border: 1px solid #dee2e6;
    display: inline-flex;
    flex-direction: column;
    background-color: #ffffff;
    width: fit-content;
    height: fit-content;
    padding-bottom: 24px;
    padding-inline: 20px;
    padding-top: 14px;
    border-radius: var(--border-radius);
    box-shadow: 8px 8px 16px 8px var(--background-color-transparent-dark);
    margin-bottom: 200px;
    max-height: 90%;
}

.product_name {

    display: flex;
    justify-content: center;
    color: black;
    font-size: 1.8rem;
    font-weight: 550;
    padding-bottom: 20px;
    
}
@media screen and (max-width: 827px) {
    .product_name {
        font-size: 1.5rem;
        padding-bottom: 10px;
    }
    
}
@media screen and (max-width: 427px) {
    .product_name {
        font-size: 1.2rem;

    }
    
}
.information_content {
    width: 65vw;
    height: calc(80vh - var(--height-navbar));
    background-color: var(--background-white);
    margin: auto;
    margin-top: 1vw;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-button);
    display: grid;
    grid-template-columns: 55% 45%;
    padding: 1vw 1vw 1.5vw 1vw;
    box-sizing: border-box;
    gap: 1.5vw;
    align-items: center;

}
@media screen and (max-width: 827px) {
    .information_content {
        flex-direction: column;
        display: flex;
        width: auto;
        padding: 1vw 1vw 4.5vw 1vw;
    }
    
}
@media screen and (max-width: 427px) {
    .information_content {
        width: auto;
        padding: 0.8rem;
        
    }
    
}

.content_img {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.img_product {
    width: 100%;
    max-height: 100%;
    height: calc(60vh - var(--height-navbar) - 4vw);
    object-fit: contain;
}
@media screen and (max-width: 827px) {
    .img_product {
        height: calc(34vh - var(--height-navbar) - 4vw);
    }
    
}

.content_description {
    composes: scroll-menu from global;
    position: relative;
    width: calc(100%);
    height: 100%;
    padding-bottom: 3vw;
    padding-right: 0.5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.description_title {
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 550;
    margin-top: 1%;
}

.description_text{
    margin-top: 5px;
    font-size: 1rem;
    margin-top: 1%;
    text-align: '-webkit-auto';
}
.description_text_button_shopee{
 
    font-size: 8xp;
    text-align: center;
    font-weight: medium;
}



@media screen and (max-width: 827px) {
    .background_container {
        margin: 8px;
        width: initial;
        margin-bottom: 0;
        padding: 10px;
    }
    .modal_content{
        margin-top: 0px;
        width: initial;
    }
    
    
}
@media screen and (max-width: 427px) {
    .background_container {
        margin: 4px;
        width: initial;
        margin-bottom: 0;
        padding: 5px;
    }
    .modal_content{
        margin-top: 0px;
        width: initial;
    }
    
}

.image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.instruction_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}