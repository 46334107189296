.content-card-list {
    position: relative;
    cursor: pointer;
    -webkit-box-shadow: var(--shadow-button);
    padding: 0px 0px 0px 0px;
    box-shadow: var(--shadow-button);
    border-radius: var(--border-radius);
    height: calc(var(--height-card-list) + 60px);
    box-sizing: border-box;
    background-color: var(--background-white);
}

.content-card-list-plus {
    display: flex;
    justify-content: center;
}

.content-name-card-list {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-size: 1.3rem;
    border-top: 1px solid var(--gray-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px 10px;
}

.grow-product-card:hover {
    height: calc(var(--height-card-list) + 90px + 1px);
}

.content-img-card-list {
    height: var(--height-card-list);
    width: 100%;
}

.img-content-card-list {
    height: var(--height-card-list);
    width: 100%;
    object-fit: contain;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.img-content-card-list-prc {
    height: var(--height-card-list);
    width: 100%;
    object-fit: contain;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.img-content-card-list-album {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.content-text-card-list {
    display: flex;
    flex-direction: row;
    white-space: pre;
    margin: auto;
}

.text-one-card-list {
    color: var(--primary-color-dark);
}

.text-two-card-list {
    color: var(--secondary-color);
    margin-left: 0.5rem;
}

.icon-addShoppingBag {
    height: 20px;
}

.options-menu-dots {
    height: 16px;
}