.floatingButton {
    position: fixed;
    bottom: 65px;
    right: 20px;
    z-index: 1000;
}


.button {
    background-color: #F2A2C0; /* Color rosado */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.button:hover {
    opacity: 0.8;
    transform: translateY(-5px);
}