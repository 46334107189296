.step-content {
  padding: 9px;
  margin-top:	20px ;
  margin-bottom:	20px ;
}

.step-image img {
  width: auto; /* Ajusta el tamaño según tus necesidades */
  height:500px;; /* Mantiene la proporción de la imagen */
  display: flex;
  margin: 0 auto; /* Centra la imagen horizontalmente */
  padding: 16px;
  justify-content: center;
  align-items: center;
}
@media  (max-width: 1500px) {
  .step-image img {
    width: auto; /* Ajusta el tamaño según tus necesidades */
    height:400px;; /* Mantiene la proporción de la imagen */
    display: flex;
    margin: 0 auto; /* Centra la imagen horizontalmente */
    padding: 16px;
    justify-content: center;
    align-items: center;
  }  
}
@media  (max-width: 900px) {
  .step-image img {
    width: auto; /* Ajusta el tamaño según tus necesidades */
    height:300px;; /* Mantiene la proporción de la imagen */
    display: flex;
    margin: 0 auto; /* Centra la imagen horizontalmente */
    padding: 8px;
    justify-content: center;
    align-items: center;
  }  
}
@media  (max-width: 600px) {
  .step-image img {
    max-width: 330px; /* Ajusta el tamaño según tus necesidades */
    height:auto;; /* Mantiene la proporción de la imagen */
    display: flex;
    margin: 0 auto; /* Centra la imagen horizontalmente */
    padding: 4px;
    justify-content: center;
    align-items: center;
  }  
}

.stepper-buttons {
  margin-bottom: 16px;
  text-align: center;
}
.stepper-progress {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.step-content-container {
  margin-top: 0px;
}
.progress-container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -12px;
}