/* .position-buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0px;
    width: 300px;
} */
*{
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}

.our-mark-container{
    display: flex;
    align-items:center;
    justify-content: center;
    gap:40px;
 
}
@media (max-width:1050px) {
    .our-mark-container{
        flex-direction: column;
        gap:20px;
    }  
}
.our-mark-container-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-bottom: 0px;
    width: 100%;
    max-width: 600px;
    transform: translate(-7%, 0%);
}
@media screen and (max-width: 1050px) {
    .our-mark-container-info{
        
        transform: translate(0%, 0%);
        justify-content: center;
    }
}
@media screen and (max-width: 720px) {
    .our-mark-container-info{
    
        justify-content: center;
    }
    
}
.our-mark-container-logo{
    max-width:60%;  /* La imagen no será más ancha que su contenedor */
    height: auto;     /* Mantiene la proporción de la imagen */
    display: block;   /* Evita que la imagen tenga un pequeño espacio debajo de ella */
    margin: 0 auto;   /* Opcional: centra la imagen horizontalmente si es un bloque */
   
}
.our-mark-container p{
    width: 500px ;
   
    display: flex;
    margin-inline: 30px;
}
@media screen and (max-width: 720px) {
    .our-mark-container p{
        max-width: 400px ;
        text-align:left;

    }
}
@media screen and (max-width: 480px) {
    .our-mark-container p{
        width: auto ;
 
    
}
}
.our-mark-container-img{
    height: 450px;
    max-width: 100%;
    display: flex;
    width: auto;
    align-self: center;
}
@media screen and (max-width: 720px) {
    .our-mark-container-img{
        height: 340px;
    }
    
}
@media screen and (max-width: 420px) {
    .our-mark-container-img{
        height: 280px;
    }
    
}