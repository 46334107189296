.align-buttons{
    display: flex;
    justify-content: center;
}
.pt-5-strong{
    font-weight: medium;
}
.JoinUs-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.info-container-ecotienda{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    
    max-width: 60%;
}
@media screen and (max-width: 720px) {
    .info-container-ecotienda{
        width: auto;
        max-width: 85%;
        margin-inline: 30px;
}
}

.img-joint-us{
    width:100%;
    height: auto;
    object-fit: cover;
}

.ecotiendas-subtitle{
    display: flex;
    justify-content: center;
    color: black;
    font-size:1.5rem;
    font-weight: 500;
    align-items: center;
    font-family: 'Montserrat';
    margin: 24px;
    margin-top: 0px;
}
@media screen and (max-width: 900px) {
    .cotiendas-subtitle{
        font-size: 1.2rem;
        margin: 16px;
        margin-top: 0px;
    }
}
@media screen and (max-width: 768px) {
    .cotiendas-subtitle{
        font-size: 1rem;
        margin: 16px;
        margin-top: 0px;
    }
    
}
.titulo-ecotienda {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
}
@media screen and (max-width: 1200px) {
    .titulo-ecotienda {
       font-size: 2.5rem;
       padding: 8px;
    }
}
@media screen and (max-width: 768px) {
    .titulo-ecotienda 
        {
        font-size: 2rem;
        padding: 0;
    }
    
}