.icons-history{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.style-logo{
    width: 250px;
    margin-bottom: 20px;
}

.style-logotipo-h{
    width: 100%;
}
@media screen and (max-width: 768px){
    .style-logotipo-h{
        width: inherit;
      height: auto;
      width: 90%;
      margin: 30px;
    }
    
}
.container-btn-h{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 50px;
}
.history-container{
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;  
}
.history-container-info{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    width: 80%;
}
@media screen and (max-width: 768px){
    .history-container-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
}

.img-About{
    width: 100%;
    height: auto;
}
.history-container-info-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.history-title {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    
}
@media screen and (max-width: 1200px) {
    .history-title {
       font-size: 2.5rem;
       padding: 8px;
    }
    
}
@media screen and (max-width: 768px) {
    .history-title {
        font-size: 2rem;
    }
}