.icon-size{
    height: 60px;
    width: 60px;
    
}
.container-sections{
    display:flex;
    
}
.sections{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.space-container{
    margin-top: 10%;
}
.space-container-bottom{
    margin-bottom: 10%;
}
.width-p{
    width: 70%;
    text-align: left;
}
.icon-container-plat{
    background-color: #b0d7b3;
    border-radius: 50%;
    height: 180px;
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.text-style{
    color: black;
}
.img-store{
    height: 57px;
    width: 190px;
}
.img-store-container{
    display: flex;
    gap: 5px;
    align-items: center;
    width: auto;
}
.primary-button {
    display: inline-block;
    background-color: #F6C1D6; /* Color principal */
    color: black;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 4px;

}

.primary-button:hover {
    background-color: #8C2769; /* Color más claro al pasar el cursor */
    color:white;
    
}
.OurPlatforms-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 24px;
    width: 60%;
    margin-bottom: 24px;

}
@media screen and (max-width: 720px) {
    .OurPlatforms-container {
        width: auto;
        max-width: 85%;
        margin-inline: 30px;
    }
}


.info-container-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    max-width: 60%;

}
@media screen and (max-width: 900px) {
    .info-container-text{
        max-width: 350px;
    }
    
}
.store-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 16px;
}
.plataformas-title{
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
    .plataformas-title{
        text-align: center;
        justify-content: center;
        display: flex;
       font-size: 2.5rem;
       padding: 8px;
    }
}
@media screen and (max-width: 768px) {
    .plataformas-title {
        font-size: 2rem;
        padding: 0;
    }
    
}