.section-style{
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border: 0px;
    margin: 24px;

}
@media screen and (max-width: 768px) {
    .section-style{
        flex-direction: column;
        padding: 16px;
        margin: 10px;
    }
    
}
.section-style h2{
    font-weight:500;
    margin: 0px;
    font-family: 'Montserrat';
    font-size: 2.3rem;
    color: black;
}
@media screen and (max-width: 768px) {
    .section-style h2{
     font-size: 1.8rem;
    }
    
}
@media screen and (max-width: 468px) {
    .section-style {
        padding: 10px;
        margin:10px;
        font-size: 1.5rem;
    }
    
}
h2 span{
    color: black;
}

.section-title {
    display: flex;
    align-items: justify;
    justify-content: justify;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 2.6rem;
    font-weight: 550;
    font-family: 'montserrat';
}
