.steep{
    position: relative;
     top: -0.6em; 
    margin: auto;
    background: var(--secondary-color);
    color: white;
    border-radius: 1.2em;
    height: 2.2em;
    width: 2.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    -webkit-box-shadow: var(--shadow-button);
    box-shadow: var(--shadow-button);
    border-radius: var(--border-radius);
    background-color: var(--background-white);
    min-height: 20vh;
    width: 167px;
}
.text{
    text-align: center;
    color: var(   --secondary-color);
}
.title{
    color: var(  --primary-color-dark);
}
.link{
    text-decoration: none;
}