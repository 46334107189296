.advantage_title{
    font-weight: 500;
    margin-top: 8px;
}
.background-rounded{
    background-color: #A7D9B3;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.size-icons{
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    max-width: 100px;
}
.position-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
    gap: 8px;

   
}
@media screen and (max-width: 720px) {
    .position-col{
        margin: 0px;
        padding-inline: 30px;
        padding-bottom: 8px;
        padding-top: 8px;
    }
    
}
.aside-container{
    display: flex;
    align-items:center;
    justify-content: center;
    
}
.aside-container-info{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    width: auto;
    row-gap: 16px;
}
@media screen and (max-width: 720px) {
    .aside-container-info{
        width: auto;
    }
    .aside-container-img{
        height: 250px;
    }
}