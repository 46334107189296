.imgContainer {
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items:center;
    justify-content: center;

    max-width: 100%;
}
@media screen and (max-width: 900px) {
    .imgContainer {
        padding: 0px 0px 0px 0px;
        justify-content: center;
        max-width: 100%;
    }
}

.img {
    height: 400px;
    width: 400px;
    justify-content: flex-start;
    margin: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
    .img {
        height: 250px;
        width: auto;
        margin:4%;
        margin-top: 0px; 
        display: flex;
        justify-content: center;
       
    }
}

.imgTitle {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    
}
@media screen and (max-width: 1200px) {
    .imgTitle {
        margin-bottom: 40;
        margin-top: 0px;
        padding-top: 0;
        text-align: center;
        justify-content: center;
        display: flex;
       font-size: 2.5rem;
    }
    
}
@media screen and (max-width: 768px) {
    .imgTitle {
        margin-bottom: 20;
        margin-top: 0px;
        text-align: center;
        justify-content: center;
        display: flex;
        font-size: 2rem;
    }
}

.tittle {
    margin-bottom: 40px;
    font-family: 'Montserrat';
}

.content {
    margin-top: 10px;
}

.imgInfo {
    text-align: justify;
    padding-right: 0;
    padding-left: 0;
    font-family: 'Montserrat';
    justify-content: center;
    align-content: center;
    font-size: medium;
    display: flex;
    flex-direction: column;
    max-width: 750px;
    margin: 24px; 
    padding: 16px;
}
@media screen and (max-width: 768px) {
    .imgInfo {
        padding: 0;
        text-align: center;
        max-width: 350px;
    }
}


@media only screen and (max-width: 913px) {

    .imgContainer {
        flex-direction: column;
    }
    .imgInfo {
        text-align: center;
        padding-right: 0;
        
    
    }
}
.formato {
    font-family:'Montserrat';
        font-Size:medium;
        color:#80225f;
        align-items:center;
        display:flex;      
        list-Style-Type:none;
        text-align: start;
}
.imgtext{
    display: block;
    margin-bottom: 24;
    width: 80%;

}
@media screen  and (max-width:913px){
    .imgtext{
        width: auto;
    } 

}