.card_container {
    composes: scroll-menu from global;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
    width: 100%;
    
}

.search_button {
    background-color: rgba(231, 153, 175, 0.3);
    border-radius: var(--border-radius);
    border-bottom: none;
    font-size: 0.8rem;

}

.showcase_subtitle {
    display: flex;
    justify-content: center;
    color: black;
    font-size:1.8rem;
    font-weight: 500;
    align-items: center;
    height: 60px;
    font-family: 'Montserrat';
}
@media screen and (max-width: 900px) {
    
}
.bestseller_content_grid {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

}

.bestseller_content {
    display: grid;
    align-items:start;
    align-content: center;
    justify-items: center;
    width: 85%;
    column-gap: 20px;
    row-gap: 20px;
    grid-auto-rows: calc(var(--height-card-list) + 10vh);
    grid-template-columns: repeat(5, 1fr);
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: 30px;
}
@media screen and (max-width: 1610px) {
    .bestseller_content  {
        grid-template-columns: repeat(5, 1fr);
        row-gap: 20px;
        grid-auto-rows: calc(var(--height-card-list) + 12vh);
    }
    
}
@media screen and (max-width: 1510px) {
    .bestseller_content  {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 20px;
        grid-auto-rows: calc(var(--height-card-list) + 14vh);
    }
    
}
@media screen and (max-width:1360px) {
    .bestseller_content  {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 20px;
        grid-auto-rows: calc(var(--height-card-list) + 16vh);
    }
}
@media screen and (max-width: 1092px) {
    .bestseller_content  {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: calc(var(--height-card-list) + 18vh);
        
    }
}

@media screen and (max-width: 827px) {
    .bestseller_content  {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: calc(var(--height-card-list) + fit-content);
        row-gap: 20px;
    }
}

@media screen and (max-width: 545px) {
    .bestseller_content  {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
       
        
    }
    
}
@media screen and (max-width: 445px) {
    .bestseller_content   {
        grid-template-columns: repeat(1, 1fr);
        row-gap:20px;
       
    }
    
}
@media screen and (max-width: 345px) {
    .bestseller_content   {
        grid-template-columns: repeat(1, 1fr);
        row-gap:20px;
      
    }
    
}

.product_content_grid {
    width: 100%;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
   

}

.products_content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: calc(var(--height-card-list) + 17vh);
    column-gap: 17px;
    row-gap: 40px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-items: center;
    width: 85%;
    margin-bottom: 0px;
    margin-top: 40px;

}
@media screen and (max-width: 1510px) {
    .products_content  {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 60px;
      
    }
}

@media screen and (max-width:1360px) {
    .products_content  {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 60px;
      
    }
}
@media screen and (max-width: 1092px) {
    .products_content  {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 70px;
    
    }
}

@media screen and (max-width: 827px) {
    .products_content  {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: calc(var(--height-card-list) + fit-content);
        row-gap: 20px;
        
    }
}

@media screen and (max-width: 545px) {
    .products_content  {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: calc(var(--height-card-list) + fit-content);
        
        
    }
}
@media screen and (max-width: 445px) {
    .products_content  {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: calc(var(--height-card-list) + fit-content);
       
       
    }
    
}

.products_content:hover {
    margin-bottom: 0;
 
}

.nav_button_container {
    display: flex;
    flex-direction: row;

}

.nav_button {
    height: 35px;
    width: 35px;
    background-color: var(--primary-color-dark);
    border-radius: 50%;
    display: flex;
    align-items: center;
    max-height: 35px;
    max-width: 35px;
    margin: 10px 10px;
    min-width: 35px;
    min-height: 35px;
    justify-content: center;
}

.bottom_space {
    min-height: 400px;
}
