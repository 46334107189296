.container-btn-register{
    display: flex;
    justify-content: center;
    margin: 40px;
    width: auto;
    height: auto;
}
.register-title{
    font-family: 'Montserrat';
    font-size: 36px;
    color: #8C2769;
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    font-weight: 500;
}

.register-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;   
    height: 100%;
   
}
.btn-register{
    background-color: #8C2769;
    color: white;
    font-size: 20px;
    font-family: 'Montserrat';
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.register-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}
@media screen and (max-width: 768px){
    .register-form{
        width: 80%;
    }
    
}
