.list-products-bag{
    width: 70%;
    max-height: calc(100vh - var(--height-navbar));
    overflow-y: auto;
    display: ruby;
    gap: 10px;
    align-content: center;
    justify-content: center;
}
.shoping-bag-container{
    display: flex;
    overflow-x: clip;
    height: calc(85vh - var(--height-navbar)) ;
}

.payment-summary{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    min-width: 200px;
    padding: 8px;
    margin: 16px;
}
.price{
    color: var(--primary-color-dark);
}
.payment-summary-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    height: calc(85vh - var(--height-navbar));
}
.payment-summary hr{
    width: 100%;
}

@media only screen and (max-width: 800px) {

    .shoping-bag-container{
         flex-direction: column;
         height: unset;
    }
    .list-products-bag {
         width: initial !important;
    }
    .payment-summary {
         width: auto;
    }
}


.payment-summary-tittle{
   
}
