.title {
    text-align: center;
    color: black;
    font-size: 36px;
}
@media screen and (max-width: 768px) {
    .title {
        font-size: 24px;
    }
}
@media screen and (max-width: 480px) {
    .title {
        font-size: 20px;
    }
}
.titleH {

    color: var(--secondary-color-dark);

}

.steeps {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
   
    gap: 4vw;
    justify-content: center;
}

.steeps div{
    flex: 0 0 calc(20% - 40px);
}

.info_card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vh
}

.info_card {
    display: ruby;
    width: auto;
    text-align: center;
    padding: 4px;
    color: var(--secondary-color);
}