.input-style {
    width: calc(100% - 10px);
    padding: 5px 0px 8px 0px;
    margin: 4px 5px;
    display: inline-block;
    outline: none;
    border: none;
    font-size: 2rem;
    color: var(--green-color);
    border-bottom: 1.5px solid var(--gray-color);
    box-sizing: border-box;
    /* background: transparent; */
}

.input-style::selection {
    color: white;
    background: var(--background-blue);
  }

.input-select-style-container {
    display:flex;
    flex-direction: column;
}
.input-select-style {
    width: calc(100% - 10px);
    position: relative;
    cursor: pointer;
    color: var(--green-color);
    background: transparent;
    border: none;
    border-bottom: 1.5px solid var(--gray-color);
    outline: none;
    line-height: 3rem;
    padding: 5px 0px 8px 0px;
    margin: 4px 5px;
    display: block;
    user-select: none;
    z-index: 1;
}

.input-style:-webkit-autofill,
.input-style:-webkit-autofill:hover,
.input-style:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--green-color);
    transition: background-color 5000s ease-in-out 0s;
}


.input-style::placeholder {
    color: var(--gray-color-light);
}

.input-style.center-input {
    text-align: center;
}

.input-style.center-input::placeholder {
    text-align: center;
}

.text-error-input {
    height: 20px;
    color: var(--red-color);
    font-size: 1.5rem;
    margin: 0px 5px;
}

.text-error-input.center-input {
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .input-style {
        width: calc(100% - 10px);
        padding: 5px 0px 8px 0px;
        margin: 4px 5px;
        font-size: 1rem;
        border-bottom: 1.5px solid var(--gray-color);
        box-sizing: border-box;
        /* background: transparent; */
    }

    .text-error-input {
        height: 20px;
        font-size: 0.8rem;
        margin: 0px 5px;
    }
}

@media only screen and (min-width: 600px) {
    .input-style {
        width: calc(100% - 10px);
        padding: 5px 0px 8px 0px;
        margin: 4px 5px;
        font-size: 1rem;
        border-bottom: 1.5px solid var(--gray-color);
        box-sizing: border-box;
        /* background: transparent;*/
    }

    .text-error-input {
        height: 20px;
        font-size: 0.8rem;
        margin: 0px 5px;
    }
}

@media only screen and (min-width: 768px) {
    .input-style {
        width: calc(100% - 10px);
        padding: 5px 0px 8px 0px;
        margin: 4px 5px;
        font-size: 1rem;
        border-bottom: 1.5px solid var(--gray-color);
        box-sizing: border-box;
        /* background: transparent; */
    }

    .text-error-input {
        height: 20px;
        font-size: 0.8rem;
        margin: 0px 5px;
    }
}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}