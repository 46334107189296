.paragraph {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}
/* Fondo difuminado */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Fondo semitransparente */
    backdrop-filter: blur(5px); /* Efecto de difuminado */
    z-index: 200; /* Debe estar detrás del modal */
    display: none; /* Oculto por defecto */
}

/* Mostrar fondo cuando el modal está activo */
.overlay.active {
    display: block;
}

/* Estilos del contenido del modal */
.modal-terms-content {
    background-color: #FFECF3;
    position: relative;
    width: 90%; /* Ajuste automático al tamaño de pantalla */
    max-width: 700px; /* Limitar ancho máximo para pantallas grandes */
    min-width: 320px; /* Ancho mínimo para pantallas pequeñas */
    height: auto; /* Altura adaptable */
    margin: auto;
    padding: 2%;
    border-radius: var(--border-radius);
    z-index: 300;
    overflow-y: auto; /* Permitir scroll si el contenido es muy alto */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); /* Sombra para mejor visibilidad */
}

/* Contenedor del modal */
.modal-terms {
    background-color: rgba(255, 255, 255, 0); /* Fondo transparente */
    z-index: 300;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

/* Botón de cierre del modal */
.close-modal-terms {
    background-color: var(--secondary-color-light);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-modal-terms:active {
    transform: scale(0.95);
}

/* Icono del botón de cierre */
.close-icon-terms {
    fill: white;
    width: 15px;
    height: 15px;
}

/* Contenido del texto en términos */
.content-data-terms {
    max-height: 70vh; /* Limitar altura del contenido para mejor lectura */
    margin: auto;
    padding: 1rem;
    overflow-y: auto;
}

.text-all-terms-and-conditions {
    font-size: 1rem;
}

.title-terms-and-conditions {
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
}

/* Botón para aceptar términos */
.button-accept-termns {
    width: 100%;
    max-width: 300px;
    margin: 1rem auto 0;
}

/* Responsividad (media queries optimizados) */
@media only screen and (max-width: 480px) {
    .modal-terms-content {
      margin: 0;
    }

    .content-data-terms {
        max-height: 60vh; /* Ajuste para pantallas muy pequeñas */
        margin-top: 1.2rem;
    }
    .modal-terms {
        width: fit-content;
        margin: auto;
    }
}

@media only screen and (min-width: 768px) {
    .modal-terms-content {
        width: 80%;
    }
    .content-data-terms {
        margin-top: 1rem;
    }
    .modal-terms {
        width: 95%;
    }
}

@media only screen and (min-width: 1024px) {
    .modal-terms-content {
        width: 70%;
    }
    .content-data-terms {
       
        margin-top: 0.5rem;
    }
}
