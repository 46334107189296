
.items-center{
    display:flex;
    flex-direction: column;
    align-items: justify;
    margin-bottom: 50px;
    text-align: justify;
}
/* Justificar texto de las listas */
ul {
    text-align: left;
    padding-left: 1.5rem;

}
p{
    text-align: left;
    font-family: 'Montserrat';
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    
}
.p1{
    text-align:left;
    font-family: 'Montserrat';
    margin: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    
}

.imgEcosistema {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 600px;
    margin-inline: 24px;
    object-fit: contain;
}
@media screen and (max-width: 768px) {
    .imgEcosistema {
        max-height: 400px; /* Ajuste de altura para pantallas medianas */
        margin-inline: 16px;
    }
}

@media screen and (max-width: 480px) {
    .imgEcosistema {
        max-height: 280px; /* Altura máxima para pantallas pequeñas */
        margin-inline: 8px;
    }
}
.img-container {
    display: flex;
    justify-content: center;
    
}
.Ecosystem-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
    
}

.info-container-ecosistema {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 60%;
    gap: 0px;
    margin-bottom: 20px;
}
@media screen and (max-width: 720px) {
    .info-container-ecosistema {
        width: auto;
        max-width: 85%;
        margin-inline: 30px;
    }
    
}
.titulo-ecosistema {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
}
@media screen and (max-width: 1200px) {
    .titulo-ecosistema{
        text-align: center;
        justify-content: center;
        display: flex;
       font-size: 2.5rem;
       padding: 8px;
    }
}
@media screen and (max-width: 768px) {
    .titulo-ecosistema {
        font-size: 2rem;
        padding: 0;
    }
    
}
.subtitulo-ecosistema {
    display: flex;
    justify-content: center;
    color: black;
    font-size:1.5rem;
    font-weight: 500;
    align-items: center;
    font-family: 'Montserrat';
    margin: 24px;
    margin-top: 0px;
}
@media screen and (max-width: 900px) {
    .subtitulo-ecosistema  {
        font-size: 1.5rem;
        margin: 16px;
        margin-top: 0px;
    }
}
@media screen and (max-width: 768px) {
    .subtitulo-ecosistema {
        font-size: 1.4rem;
        margin: 16px;
        margin-top: 0px;
    }
    
}