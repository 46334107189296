.card-title {
    color:black;
    text-align: center;
    line-height: 1.3rem;
    overflow: hidden;
    display: flex;
    font-size: medium;
    font-family: 'Montserrat' ;
    align-items: flex-start;
    height: 2.8rem;
    justify-content: center;
    margin: 0;
    padding: auto;

}
.icon-size{
    height: 40px;
    width: 40px;
}
.icon-star-space{
    margin: 5px;
}
.icon-star-container{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.product-img-container{
    max-height: 200px;
    padding-top: 16px;
}
.product-img-size{
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}
.product-img-size-eco{
    max-height: 180px;
    aspect-ratio: 1 / 1;
    object-fit: fill;
    padding-top: 20px;
    max-width: 250px;

}
.btn-container{
    display: grid;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
   
}
.card-style{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}
.img-container{
    height: 200px;
}
.img-card-new{
    border-radius: 20px 20px 0 0;
}
.space-bottom-news{
    margin:  40px 0px 70px 0;
}
.icons-ecotiendas{
    height: 30px;
}
.removed-padding{
    padding: 0;
}
.align-icons-eco{
    display: flex;
    flex-direction: column;
}
.uppercase{
    text-transform: uppercase;
}
.border-img-card{
    border-radius: 20px 20px 0 0;
    
}

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Crear tres columnas iguales */
    gap: 40px; /* Agregar un espacio entre las filas y columnas */
    width: auto; /* Establecer un ancho máximo para el contenedor */
    margin: 0 auto; /* Centrar el contenedor */
    justify-content: center;
}
@media screen and (max-width: 1200px) {
    .card-container {
        grid-template-columns: repeat(3, 1fr); /* Crear tres columnas iguales */
        gap: 30px; /* Reducir el espacio entre las filas y columnas */
    }
    
}
@media screen and (max-width: 768px) {
    .card-container {
        grid-template-columns: repeat(1, 1fr); /* Crear una sola columna */
        gap: 20px; /* Reducir el espacio entre las filas y columnas */
    }
    
}

.card-item {
    margin:5%;
    max-width: 300px;
    border-radius: 20px;
    -webkit-box-shadow: var(--shadow-button);
    box-shadow: var(--shadow-button);
    box-sizing: border-box;
    background-color: var(--background-white);
    margin-bottom: 0px; 
    width: 250px;
    height: 400px;
}
.card-img {
    
    width: auto; /* Ajustar la imagen al ancho de la tarjeta */
    height: 220px; /* Mantener la proporción de la imagen */
    margin: 0 auto  ; /* Centrar la imagen */
    display: block; /* Alinear la imagen al centro */
    padding-top:  24px;
  }
  .card-subtitle {
    color: var(--text-gray);
    font-size: 0.8rem;
    text-align: start;
    margin: 0;
    height: 1rem;
    padding: 0;

   
  }
  .card-text {
    color: var(--text-gray);
    font-size: 0.8rem;
    text-align: start;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    overflow: auto;
    text-overflow: ellipsis;
    max-height: 12rem;
    display: -webkit-box;
  } 
.card-body{
    padding: 8px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    align-content: center;
}
.card-style2{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    padding: 0px;
    margin: 0px;
}
.grid-container {
    display: ruby;
    align-items: center; /* centrar el contenido verticalmente */
    justify-content: flex-start; /* posicionar el Button al final horizontalmente */
  }