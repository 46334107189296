/* Base Styles */
.showCase-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.showCase-title-container {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 80px;
    justify-content: flex-end;
    margin-bottom: 16px;
    margin-top: 16px;
    margin-left:16px ;
    width: 60%;
}

.showCase-title {
    text-align: center;
    color: black;
    font-size: 2.8rem;
    font-weight: 550;
    font-family: 'Montserrat';
   
   
   

}
@media screen and (max-width: 1490px) {
    .showCase-title {
      
        font-size: 2.5rem;
    }
    
}
@media screen and (max-width: 1200px) {
    .showCase-title {
        font-size: 2.2rem;
    }
    
}
@media screen and (max-width: 900px) {
    .showCase-title {
        font-size: 1.8rem;
    }
    
}
/* Shopping Bag and Icon Containers */
.showCase-shoppingBag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}

.showCase-icon-container,
.showCase-bag-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color-dark);
    border-radius: 25%;
    height: 50px;
    width: 50px;
}

.showCase-bag {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-right: 3px;
}

.spreader-bar-navbar {
    border-right: 1px solid var(--gray-icon);
    height: 24px;
    margin: 0 8px;
    font-family: "Montserrat";
}

/* Responsiveness */
@media screen and (max-width: 900px) {
    .showCase-title {
        display: none;
        font-size: 1.4rem;
    }

    .showCase-bag-container img,
    .showCase-icon-container,
    .showCase-bag-img-container {
        height: 47px;
        width: 54px;
    }

    .showCase-shoppingBag {
        justify-content: center;
        width: 100%;
    
    }
}

@media screen and (max-width: 768px) {
 

    .showCase-title-container {
        gap: 0px;
        flex-direction: row;
        align-items: center;
    }

    .showCase-title {
        font-size: 1.2rem;
    }
    .showCase-shoppingBag {
       gap: 0px;
    }
}

@media screen and (max-width: 450px) {
    .showCase-title-container {
       
        width: auto;
    }
}

@media screen and (max-width: 400px) {
    .showCase-bag-img-container {
        height: 20px;
        width: 20px;
    }
}

/* Utility Classes */
.link-style {
    color: var(--secondary-color);
    font-weight: bold;
    margin-right: 4px;
}

.search_button {
    background-color: rgba(231, 153, 175, 0.3);
    border-radius: var(--border-radius);
    font-size: 0.8rem;
}

/* Image Container */
.image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.instruction_image {
    width: 150%;
    height: auto;
    border-radius: 8px;
    padding-bottom: 5px;
}
.showCase-subTitle{
    display: flex;
    justify-content: center;
    color: black;
    font-size:1.8rem;
    font-weight: 500;
    align-items: center;
    height: 60px;
    font-family: 'Montserrat';
}
