.style-mision{
    width: 80%;
    height: 80%;
}
.style-mision-a{
    width: 60%;
    height: 50%;
    padding-bottom: 2%;
}
.Ouridentity-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   

}
.Ouridentity-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
    align-items: center;
    justify-items: center;
    width: 60%;
}
@media screen and (max-width: 768px){
    .Ouridentity-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    
}
.Ouridentity-sub-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50%;
}
@media screen and (max-width: 768px){
    .Ouridentity-sub-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    
}
.img-About{
    width: 100%;
    height: auto;
}
.Identidad-title {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    
}
@media screen and (max-width: 1200px) {
    .Identidad-title  {
       font-size: 2.5rem;
       padding: 8px;
    }
    
}
@media screen and (max-width: 768px) {
    .Identidad-title  {
        font-size: 2rem;
    }
}